import { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import NavDropdown from "react-bootstrap/NavDropdown";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import i18n from './i18n';
import bulletin from './bulletin';
import { JSONObject, DownloadModalProps } from './type';
import "./style.css";

// Downloads
const DownloadModal = (props: DownloadModalProps) => {
  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {i18n.t('download')} NoyAcg APP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{i18n.t('notice')}</h4>
        <p>{i18n.t('public invitation bulletin')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{i18n.t('close')}</Button>
        <a href={`./downloads.html?a=${props.isChinaMainland ? "cm" : "gl"}`} target="_blank" rel="noreferrer"><Button>{i18n.t('download')}</Button></a>
      </Modal.Footer>
    </Modal>
  );
}

interface MyProps { }
interface MyState {
  showToast: boolean;
  downloadShow: boolean;
  isChinaMainland: boolean;
  isCA: boolean;
  reload: boolean;
  toastUser: string;
  toastCover: string;
  toastMsg: string;
  toastTime: string;
  r18Show: boolean;
  c: string;
}

class App extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    if (window.location.hostname.split(".").reverse().splice(0, 2).reverse().join(".") !== "noy.asia") window.location.replace("https://noy.asia/" + window.location.search);
    this.state = {
      showToast: false,
      downloadShow: false,
      isChinaMainland: false,
      isCA: false,
      reload: false,
      toastUser: '',
      toastCover: '',
      toastMsg: '',
      toastTime: '',
      r18Show: false,
      c: '',
    }
  }

  ar() {
    let body = document.getElementsByTagName("body")[0];
    body.style.opacity = "1";
    let si = setInterval(() => {
      body.style.opacity = String(Number(body.style.opacity) - 0.01);
      if (Number(body.style.opacity) === 0.01) {
        clearInterval(si);
        document.write('<style>body,html{margin:0;padding:0}</style><div id="ar" style="display:flex;align-items:center;justify-content:center;height:100vh;flex-direction:column"><img src="./nr.webp" alt="" style="width:125px"><h1 style="margin-bottom:0">訪問限制</h1><div style="max-width:90%"><p>你所在的國家/地區暫時不支援 NoyAcg</p></div></div><p style="position: fixed;width: 100%;bottom: 0;text-align: center;">Powered By NoyNetwork Ltd. (CA)</p>');
        let ar: HTMLElement = document.getElementById("ar") as HTMLElement;
        ar.style.opacity = "1";
        let si2 = setInterval(() => {
          ar.style.opacity += 0.01;
          if (Number(ar.style.opacity) === 1.01) clearInterval(si2);
        });
      };
    }, 5);
  }

  componentDidMount() {
    // Get bg
    // https://ci.cncn3.cn/8f0912b0f46cee4a6a19492d9a3e7bb5.webp // 冬
    // https://ci.cncn3.cn/626c72f387440d4550778a007f467e06.webp // 春
    // https://ci.cncn3.cn/41ce655fd84ca274a9980fb2d5f24f2f.webp // 夏
    // https://ci.cncn3.cn/36919ebc4153019cba97566c5aa03e95.webp // 秋
    // Get Config
    axios({
      url: "/msg.json"
    }).then(res => {
      if (res.data.status) {
        this.setState({
          showToast: true,
          toastUser: res.data.user,
          toastCover: res.data.cover,
          toastMsg: res.data.msg,
          toastTime: res.data.time
        })
      }
    });
    // Get IP
    axios({
      url: "https://public-api.noy.asia/api/geoip"
    }).then(res => {
      let ctt: JSONObject = {
        "HK": i18n.t("ctt.HK") as string,
        "TW": i18n.t("ctt.TW") as string,
        "JP": i18n.t("ctt.JP") as string,
        "CA": i18n.t("ctt.CA") as string,
      }
      let country: string; // country: 國家/地區
      if (ctt[res.data.country] !== undefined) {
        country = ctt[res.data.country] as string;
      } else if (res.data.country === "CN") {
        country = i18n.t("ctt.CN"); this.setState({ isChinaMainland: true });
        this.ar();
      } else {
        country = res.data.country;
      }
      this.setState({ r18Show: true, c: country });
    }).catch(() => {
      this.setState({ r18Show: true, c: "你居住的國家" });
    })
  }

  render() {
    let { showToast, toastCover, toastUser, toastMsg, toastTime, downloadShow, isChinaMainland, r18Show, c, reload } = this.state;

    const setLang = (lang: string) => {
      i18n.changeLanguage(lang, (err) => {
        if (err) return console.log('i18n err', err);
        localStorage.setItem("lang", lang);
        this.setState({ "reload": !reload })
      })
    }

    const bulletin_list: Array<JSONObject> = (bulletin[i18n.language] !== undefined ? bulletin[i18n.language] : bulletin['en']) as Array<JSONObject>;

    return (
      <div>
        <style id="style"></style>
        <Navbar bg="light" expand="lg" variant="light" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 10 }}>
          <Container>
            <Navbar.Brand href="#home">
              <img
                alt=""
                src="/favicon.ico"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              NoyAcg
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <Nav.Link className="navLink" href="https://board.noy.asia" target={"_blank"}>{i18n.t('community')}</Nav.Link>
                <NavDropdown className="navLink" title={i18n.t('group')} id="basic-nav-dropdown">
                  <NavDropdown.Item href="https://t.me/noyacg" target="_blank" rel="noreferrer">Telegram</NavDropdown.Item>
                  <NavDropdown.Item href="https://discord.gg/W8CxcAstGh" target="_blank" rel="noreferrer">Discord</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link className="navLink" href="#bulletin">{i18n.t('bulletin board')}</Nav.Link>
                <NavDropdown className="navLink" title={i18n.t('lang')} id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={() => setLang("en")}>English</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => setLang("zh-HK")}>中文</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => setLang("ja")}>日本語</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link className="navLink" href="#downloads">{i18n.t('start now')}</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="incode">
          <p className="uhash">{i18n.t('public invitation code')}{/* ：<b className="uhash-b">NoyAcg2023</b> */}（停止使用）</p>
        </div>
        <div id="home">
          <div id="bg" style={{ backgroundImage: 'url(./sm.webp)' }}></div>
          <div className="text home-text">
            <h1 className="s-title" style={{ fontSize: '46px', marginBottom: '30px' }}>{i18n.t('hello welcome')}</h1>
            <p className="de-text s-title">{i18n.t('noyacg title')}</p>
            {/* backgroundColor: '#e9f3ff', border: 0, color: '#2d8eff' */}
            <a href="#bulletin"><Button className="noy-button" style={{ backgroundColor: '#272c32', border: 0, color: '#FFF' }} size="lg">{i18n.t('bulletin')}</Button></a>
            <a href="#downloads"><Button className="noy-button" variant="primary" size="lg">{i18n.t('start now')}</Button></a>
          </div>
        </div>
        <div style={{ backgroundColor: '#FFF' }}>
          <div style={{ textAlign: 'center', padding: '50px 16px 100px 16px', margin: '0 auto', maxWidth: 900 }}>
            <h2 style={{ fontSize: '2rem' }}>{i18n.t('about us')}</h2>
            <p className="de-text">{i18n.t('noyacg is a self-developed site')}</p>
            <Accordion defaultActiveKey="0" style={{ marginTop: 32 }}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{i18n.t('about noyteam')}</Accordion.Header>
                <Accordion.Body className="noy-accordion">
                  {i18n.t('noyteam des')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>{i18n.t('about noyacg')}</Accordion.Header>
                <Accordion.Body className="noy-accordion">
                  {i18n.t('noyacg des')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>{i18n.t('sponsor us')}</Accordion.Header>
                <Accordion.Body>
                  <img style={{ width: '150px' }} src="./rb.webp" alt="" />
                  <p style={{ marginTop: '15px', fontWeight: 'bold' }}>{i18n.t('sponsor des')}</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div style={{ textAlign: 'center', padding: '30px 0 100px 0', margin: '0 16px' }} id="bulletin">
          <h2 style={{ fontSize: '2rem' }}>{i18n.t('bulletin board')}</h2>
          <p className="de-text">{i18n.t('bulletin des')}</p>
          <Container style={{ marginTop: 32, margin: '0 auto', maxWidth: bulletin_list.length < 3 ? 1100 / (4 - bulletin_list.length) : 1000 }}>
            <Row>
              {bulletin_list.map((item, index) => {
                return (<Col key={index} md={bulletin_list.length < 3 ? 12 / bulletin_list.length : 4} xs="12" style={{ marginTop: 20 }}>
                  <a href={item.link as string} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#000000' }}>
                    <Card className="noy-card">
                      <Card.Img variant="top" src={item.img as string} />
                      <Card.Body>
                        <Card.Title>{item.title as string}</Card.Title>
                        <Card.Text style={{ color: '#5b617c' }}>{item.text as string}</Card.Text>
                      </Card.Body>
                    </Card>
                  </a>
                </Col>)
              })}
            </Row>
          </Container>
        </div>
        <div style={{ backgroundColor: '#282c47', textAlign: 'center' }} id="downloads">
          <h2 style={{ fontSize: '2rem', color: '#FFFFFF', margin: 0, padding: "60px 0 30px" }}>{i18n.t('start now')}</h2>
          <Button className="noy-button" variant="primary" size="lg" onClick={() => { this.setState({ downloadShow: true }) }}>Android</Button>
          <a href="https://noy.icu" target="_blank" rel="noreferrer"><Button className="noy-button" variant="primary" size="lg">{i18n.t('website')}</Button></a>
          <Container style={{ padding: '40px 0 30px', overflow: 'hidden' }}>
            <Row className="bgx">
              <Col md="4" xs="12">
                <small style={{ color: '#5b617c', fontSize: '80%', fontWeight: '400' }}>© NoyNetwork Ltd. 2021-{new Date().getFullYear()}</small>
              </Col>
              <Col md="4" xs="12">
                <a href="https://twitter.com/noysupport" target="_blank" rel="noreferrer" style={{ margin: '0 5px' }}>
                  <img src="/logo-twitter.svg" alt="" style={{ width: 20 }} />
                </a>
                <a href="https://github.com/noyteam" target="_blank" rel="noreferrer" style={{ margin: '0 5px' }}>
                  <img src="/logo-github.svg" alt="" style={{ width: 20 }} />
                </a>
                <a href="mailto:admin@noy.asia" target="_blank" rel="noreferrer" style={{ margin: '0 5px' }}>
                  <img src="/mail.svg" alt="" style={{ width: 20 }} />
                </a>
              </Col>
              <Col md="4" xs="12">
                <small style={{ color: '#5b617c' }}><a style={{ textDecoration: 'none' }} href="/privacy_agreement.html" target="_blank">{i18n.t('user agreement')}</a> · Powered by <a style={{ textDecoration: 'none' }} href="/" target="_blank" rel="noreferrer">NoyNetwork Ltd.</a></small>
              </Col>
            </Row>
          </Container>
        </div>

        <DownloadModal
          show={downloadShow}
          onHide={() => this.setState({ downloadShow: false })}
          isChinaMainland={isChinaMainland}
        />

        <Modal
          show={r18Show}
          onHide={() => this.setState({ r18Show: false })}
          size={i18n.language === "zh-HK" ? undefined : "lg"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Warning!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            <img height="100px" width="100px" style={{ margin: 24 }} src="/R18.webp" alt="" />
            <h3>{i18n.t('adult')}</h3>
            <h4>{i18n.t('lt').replace('{area}', c)}</h4>
            {isChinaMainland && <p><b>Warning: </b>China Mainland may not be applicable.</p>}
            <p>*{i18n.t('read our')} <a href="/privacy_agreement.html" target={"_blank"}>{i18n.t('user agreement')}</a></p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ r18Show: false })}>{i18n.t('continue')}</Button>
          </Modal.Footer>
        </Modal>
        <Toast onClose={() => this.setState({ showToast: false })} show={showToast} delay={5000} autohide className="noy-toast">
          <Toast.Header>
            <img
              src={toastCover}
              style={{ width: 24 }}
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastUser}</strong>
            <small>{toastTime}</small>
          </Toast.Header>
          <Toast.Body>{toastMsg}</Toast.Body>
        </Toast>
      </div >
    );
  }
}

export default App;
