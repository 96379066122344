import { JSONObject } from './type';

const list: JSONObject = {
    "zh-HK": [
        {
            "link": "https://board.noy.asia/post/1",
            "title": "3.0 内部測試即將開始",
            "text": "佈告",
            "img": "https://bucket.noy.asia/image/global-2.webp"
        },
        {
            "link": "https://board.noy.asia/post/5",
            "title": "公共邀請停用!",
            "text": "佈告",
            "img": "https://bucket.noy.asia/image/global-2.webp"
        },
    ],
    "en": [
        {
            "link": "APP 3.0 内部測試即將開始",
            "title": "Internal testing for 3.0 is about to commence",
            "text": "notice",
            "img": "https://bucket.noy.asia/image/global-2.webp"
        },
        {
            "link": "https://board.noy.asia/post/5",
            "title": "Public invitation code is disabled! (Chinese)",
            "text": "notice",
            "img": "https://bucket.noy.asia/image/global-2.webp"
        },
    ],
}

export default list;