import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

const resources: Resource = {
    en: {
        translation: {
            ctt: {
                "HK": "Hong Kong",
                "TW": "Taiwan",
                "JP": "Japan",
                "CA": "Canada",
                "CN": "China Mainland"
            },
            "community": "Board",
            "lang": "Language",
            "group": "Group",
            "download": "download",
            "notice": "Notice",
            "public invitation code": "Public invitation code",
            "close": "close",
            "bulletin": "Bulletin",
            "bulletin board": "Bulletin Board",
            "start now": "Start now",
            "hello welcome": "Welcome to NoyAcg!",
            "noyacg title": "NoyAcg is a site for hentai (/≧▽≦)/",
            "about us": "About us",
            "noyacg is a self-developed site": "NoyAcg is a self-developed site",
            "about noyteam": "About NoyNetwork Ltd.",
            "noyteam des": "NoyTeam by NoyNetwork Ltd. is the development/operating organization of NoyAcg, founded on February 19, 2020!",
            "about noyacg": "About NoyAcg",
            "noyacg des": "NoyAcg established on 1 May 2021, this is a public benefit subplatform with a fully self-developed architecture and high quality web service/standalone server set hosting.",
            "sponsor us": "Want to sponsor us?",
            "sponsor des": "We don't accept sponsorship. Without operational funds, we'll find garbage to eat on our own.",
            "bulletin des": "Or some news",
            "website": "WebSite",
            "user agreement": "User Agreement",
            "adult": "Please make sure you are an adult",
            "lt": "and in accordance with the legal terms of {area}",
            "read our": "Continued access means you have read our",
            "address": "address",
            "continue": "continue",
            "public invitation bulletin": "The public invitation code has been disabled, please find the invitation code by yourself.",
        }
    },
    "zh-HK": {
        translation: {
            ctt: {
                "HK": "香港",
                "TW": "台灣",
                "JP": "日本",
                "CA": "加拿大",
                "CN": "中國大陸"
            },
            "community": "展示板",
            "group": "群组",
            "lang": "語言",
            "download": "下載",
            "notice": "注意",
            "public invitation code": "公共邀請碼",
            "close": "關閉",
            "bulletin": "佈告欄",
            "bulletin board": "佈告欄",
            "start now": "即刻開始",
            "hello welcome": "你好，歡迎來到NoyAcg！",
            "noyacg title": "NoyAcg是一個專屬於紳士們的站點(/≧▽≦)/",
            "about us": "瞭解一下？",
            "noyacg is a self-developed site": "NoyAcg是一個完全自研架構的平台!",
            "about noyteam": "關於 NoyNetwork Ltd.",
            "noyteam des": "NoyTeam (所屬 NoyNetwork Ltd.) 是NoyAcg的開發/營運組織，成立於2020年2月19日，目標是以優質服務承載牛子和未來。",
            "about noyacg": "關於 NoyAcg",
            "noyacg des": "NoyAcg建立於2021年5月1日，是一個公益本子平台，擁有完全自研的架構和高質量網絡服務/獨立伺服器組承載！",
            "sponsor us": "想要贊助我們？",
            "sponsor des": "我們不接受贊助，如果沒有營運資金，我們會自己找垃圾吃",
            "bulletin des": "Bulletin Board",
            "website": "Web 網站",
            "user agreement": "使用者協定",
            "adult": "請確保你已滿 18 歲",
            "lt": "並符合 {area} 的法律條款",
            "read our": "繼續訪問代表您已閱讀我們的",
            "address": "位址",
            "continue": "繼續",
            "public invitation bulletin": "公共邀請碼已停用，請自行尋找邀請碼",
        }
    },
    "ja": {
        translation: {
            ctt: {
                "HK": "香港",
                "TW": "台湾",
                "JP": "日本",
                "CA": "カナダ",
                "CN": "中国本土"
            },
            "community": "ボード",
            "group": "グループ",
            "lang": "言語",
            "download": "ダウンロード",
            "notice": "備考",
            "public invitation code": "公開招待コード",
            "close": "閉じる",
            "bulletin": "掲示板",
            "bulletin board": "掲示板",
            "start now": "今すぐ始める",
            "hello welcome": "NoyAcg はこれから始まる！",
            "noyacg title": "NoyAcg はHENTAIな人たちのためのサイトです (/≧▽≦)/",
            "about us": "概要？",
            "noyacg is a self-developed site": "NoyAcg は完全自社開発プラットフォーム!",
            "about noyteam": "NoyNetwork 株式会社について",
            "noyteam des": "NoyTeam は、2020年2月19日に設立されたNoyAcgの開発・運営組織「株式会社 NoyNetwork」の一員です!",
            "about noyacg": "NoyAcg について",
            "noyacg des": "NoyAcg は2021年5月1日に開発を開始し、完全自社開発のアーキテクチャと高品質なWebサービス／スタンドアロンサーバーのセットホスティングを備えたプロボノサブプラットフォームです",
            "sponsor us": "スポンサーになりたいですか？",
            "sponsor des": "スポンサーシップは受け付けていません。運営資金がなければ、自分たちでゴミを探して食べます。",
            "bulletin des": "Bulletin Board",
            "website": "ウェブサイト",
            "user agreement": "ユーザー規約",
            "adult": "18歳以上であることをご確認ください",
            "lt": " {area} の法的条件に従うものとし",
            "read our": "継続的なアクセスは、お客様が当社の利用規約を読んだことを意味: ",
            "address": "アドレス",
            "continue": "継続",
            "public invitation bulletin": "公開招待コードは無効になっています。招待コードはご自身で見つけてください。",
        }
    },
};

let userLang: string = localStorage.getItem('lang') !== null ? String(localStorage.getItem('lang')) : '';
if (userLang === '') {
    if (["zh-HK", "zh-TW", "zh-CN", "zh-Hans", "zh-Hant"].indexOf(navigator.language) !== -1) {
        userLang = "zh-HK";
    } else {
        userLang = resources[navigator.language] !== undefined ? navigator.language : "en";
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: userLang,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;